@import "../variables";

.top-page {
	overflow-x: hidden;

	&.layout-detail {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
	}

	.layout-container {
		-webkit-flex: 1 1 100%;
		-moz-flex: 1 1 100%;
		-ms-flex: 1 1 100%;
		-o-flex: 1 1 100%;
		-khtml-flex: 1 1 100%;
		flex: 1 1 100%;
		max-width: 100%;
		display: flex;

		.page-body {
			-webkit-flex: 1 1 100%;
			-moz-flex: 1 1 100%;
			-ms-flex: 1 1 100%;
			-o-flex: 1 1 100%;
			-khtml-flex: 1 1 100%;
			flex: 1 1 100%;
			max-width: 100%;
			background-size: cover;
		}

		.top-content {
			position: relative;
			height: 100%;
			overflow: hidden;

			.board-projector {
				position: absolute;
				top: 7%;
				display: flex;
				justify-content: center;
				left: 50%;
				max-width: 100%;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				-o-transform: translateX(-50%);
				transform: translateX(-50%);
			}
		}
	}

    .set-fc{
        &-footer{
            position: fixed;
            padding: 10px;
            bottom: 0;
            width: 100%;
            background-color: rgba(000, 000, 000, 0.2);
            .footer-menu{
                .menu-item{
                    .menu-link{
                        color: #fff;
                        font-size: 12px;
                        font-weight: 400;
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .footer-powered{
                span{
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
	.footer-powered{
		span{
			color: $black-dark !important;
		}
	}

	.footer-menu{
		.menu-item{
			.menu-link{
				color: $black-dark !important;
			}
		}
	}
}